import { Component, forwardRef, Input, OnInit, Injector, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FormControlValueAccessorConnector } from '../form-control-value-accessor-connector';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective } from "ngx-mask";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    TranslateModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ],
})
export class InputComponent extends FormControlValueAccessorConnector implements OnInit, AfterViewInit {
  @Input() type: string = 'text';
  @Input() isTextarea: boolean = false;
  @Input() label: string = null;
  @Input() icon: string = null;
  @Input() actionIcon: string = null;
  @Input() infoText: string = null;
  @Input() placeholder: string = '';
  @Input() inputId = `input-${(Math.random() * 100).toFixed(0)}`;
  @Input() actionButtonTitle: string = null;
  @Input() showActionButton: boolean = false;
  @Input() preselectedValue: string = null;

  @Output() onActionButtonClick = new EventEmitter<void>();

  @ViewChild('preselectedSpan', { static: false }) preselectedSpan!: ElementRef;

  preselectedValueWidth: number = 0;

  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.getPreselectedValueWidth();
  }

  getPreselectedValueWidth(): void {
    if (this.preselectedSpan) {
      setTimeout(() => {
        if (!!this.icon) {
          this.preselectedValueWidth = this.preselectedSpan.nativeElement.offsetWidth + 48;
        } else {
          this.preselectedValueWidth = this.preselectedSpan.nativeElement.offsetWidth;
        }

        this.cdr.detectChanges();
      });
    }
  }

}
