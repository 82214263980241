import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { Employee } from '../../interfaces/employee.interface'
import { Path } from '../../app-routing.config'
import { NavigationService } from '../../entities/navigation.service'
import { AppointmentFormModel } from '../../interfaces/appointment.interface'
import { DatePipe } from '@angular/common'
import { AppStateSelectors } from '../../state/app-state.selector'
import { AppActions } from '../../state/app-state.action'
import { Observable, Subject } from 'rxjs'
import { Branch } from '../../interfaces/branch.interface'
import { AmplitudeService } from '../../entities/amplitude.service'
import { takeUntil } from 'rxjs/operators'
import { Helpers } from '../../entities/helpers'


@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit, OnDestroy {
  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>;

  activeEmployee: Employee;
  formValue: AppointmentFormModel;
  filters = null;
  companyId: string = null;
  branchId: string = null;
  branchSlug: string = null;
  onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private navigationService: NavigationService,
    private datePipe: DatePipe,
    private amplitudeService: AmplitudeService,
  ) { }

  ngOnInit() {
    this.branch$.pipe(takeUntil(this.onDestroy$)).subscribe(branch => {
      this.companyId = branch.company_id;
      this.branchId = branch.id;
      this.branchSlug = branch.slug;

      this.formValue = this.store.selectSnapshot(AppStateSelectors.appointmentFormModel);
      this.filters = this.getActualFilters();

      this.onOpenEmployeeSelection(branch);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onOpenEmployeeSelection(branch: Branch): void {
    this.amplitudeService.trackEvent(
      'widget_employee_opened',
      {
        company_id: branch.company_id,
        branch_id: branch.id,
      }
    )
  }

  goToHomePage() {
    this.router.navigate([this.branchSlug], {
      queryParamsHandling: 'merge',
    });
  }

  setEmployee(employee: Employee) {
    this.activeEmployee = employee;
  }

  selectEmployee() {
    if (this.activeEmployee) {
      this.store.dispatch(new AppActions.UpdateAppointmentForm({
        employee: this.activeEmployee
      }));

      this.amplitudeService.trackEvent(
      'widget_employee_selected',
      {
        company_id: this.companyId,
        branch_id: this.branchId,
        employee_id: this.activeEmployee.id,
      });
    }
  }

  getActualFilters() {
    if (!this.formValue) {
      return {}
    }

    const filters = {};

    const selectedServices = this.formValue?.services || [];
    if (selectedServices.length > 0) {
      filters['service_ids'] = selectedServices.map(service => service.id);
    }

    const selectedDate = this.formValue?.date || null;
    if (selectedDate) {
      filters['date'] = Helpers.toISOStringUTC(selectedDate?.date, selectedDate?.timeSlot);
    }

    filters['bookable'] = true;

    return filters;
  }

  goToServiceSetUp() {
    this.selectEmployee();
    this.router.navigate([this.branchSlug, Path.SERVICES], {
      queryParamsHandling: 'merge',
    });
  }


  goToTimeSetUp() {
    this.selectEmployee();
    this.router.navigate([this.branchSlug, Path.DATE], {
      queryParamsHandling: 'merge',
    });
  }

  goToSummary() {
    this.selectEmployee();
    this.goToHomePage()
  }

}
